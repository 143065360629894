import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { TitleBar, CenterContent, ContentPane } from './LayoutComponents';
import HeaderComponent from '../components/HeaderComponent';

import Login from '../components/Login';
import CreateUser from '../components/CreateUser';
import CreateNote from '../components/CreateNote';
import FindNote from '../components/FindNote';
import Converter from '../components/Converter';
import React from 'react';

const PublicRoutes = ({ user }) => {
  const history = useHistory();
  return (
    <>
      <TitleBar>
        <HeaderComponent history={history} />
      </TitleBar>
      <ContentPane>
        <CenterContent>
          <table><tbody><tr>
            <td width="1600px">
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/createuser" component={CreateUser} />
                <Route exact path="/converter" component={Converter} />
                <Route exact path="/findnote" component={FindNote} />
                <Route exact path="/createnote" component={CreateNote} />
                <Redirect from="*" to="/findnote" />
              </Switch>
            </td>
          </tr></tbody></table>
        </CenterContent>
      </ContentPane>
    </>
  );
};

export default PublicRoutes;
