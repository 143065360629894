import React, { Component } from 'react';
import { encodeText } from '../helpers/utils';
import { TextBox, TextArea, Button, ErrorMessage, SuccessMessage, Header } from '../helpers/LayoutComponents';
import LoginState from '../helpers/LoginState';
import axios from 'axios';

export default class CreateNote extends Component {

  constructor(props) {
    super(props);

    // if not logged in, redirect
    if(!LoginState.loggedIn) {
      this.props.history.push("/login");
    }

    this.state = { 
      keywords: '',
      description: '',
      errorMsg: '',
      successMsg: '',
    };
  }

  async attemptSubmit() {
    const response = axios.get('/encrypt', {
      params: {
        pUser: LoginState.userName,
        pKeywords: this.state.keywords,
        pDescription: encodeText(this.state.description)
      },
    });

    const resultMessage = ((await response).data).toString();
    if (resultMessage.startsWith('Success')) {      
      this.setState({errorMsg: '', successMsg: 'Note Successfully Submitted', keywords: '', description: ''});      
    } 
    else {
      this.setState({errorMsg: resultMessage, successMsg: ''});
    }
  }
  
  captureInput = e => { this.setState({[e.target.name]: e.target.value}); }

  render() {
    return (
      <div style={{paddingLeft: '10px', paddingTop: '10px'}}>
        <Header>Create Note</Header>
        <br/>
        <table><tbody>
          <tr>
            <td width="100px" style={{verticalAlign: 'top'}}>Keywords</td>
            <td width="10px"></td>
            <td width="350px"><TextBox width="350px" type='text' name='keywords' value={this.state.keywords} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td width="100px" style={{verticalAlign: 'top'}}>Description</td>
            <td width="10px"></td>
            <td width="350px"><TextArea width="350px" height="150px" type='text' name='description' value={this.state.description} onChange={this.captureInput} /></td>
          </tr>
          <tr height="10px" />
          <tr>
            <td colSpan="2" />
            <td width="350px"><Button onClick={() => this.attemptSubmit()}>Submit</Button></td>
          </tr>
        </tbody></table>
        <br/>
        <ErrorMessage>{this.state.errorMsg}</ErrorMessage>
        <SuccessMessage>{this.state.successMsg}</SuccessMessage>
      </div>
    );
  }
}
