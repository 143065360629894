import React, { Component } from 'react';
import { TextBox, TextArea, Button, Select, ErrorMessage, SuccessMessage, Header } from '../helpers/LayoutComponents';
import { encodeText, decodeText } from '../helpers/utils';
import LoginState from '../helpers/LoginState';
import ConfirmPopup from '../helpers/ConfirmPopup';
import axios from 'axios';

export default class FindNote extends Component {

  constructor(props) {
    super(props);

    // if not logged in, redirect
    if(!LoginState.loggedIn) {
      this.props.history.push("/login");
    }

    this.state = { 
      keywords: '',
      context: '',
      selectOptions: [],
      searchResults: [],
      selectIndex: 0,
      errorMsg: '',
      successMsg: '',
      showPopup: false
    };
  }

  async attemptSearch() {
    // validate search string
    if(this.state.keywords.length < 3) {
      this.setState({ errorMsg: 'Search String Needs To Be At Least 3 Characters Long', successMsg: ''});
      return;
    }

    const response = axios.get('/decrypt', {
      params: {
        pKeywords: this.state.keywords,
        pUser: LoginState.userName
      },
    });
    
    try {
      var resultData = (await response).data;

      var fillList = [];
      for (var i = 0; i < resultData.length; i++) {
        fillList.push({ label: resultData[i].keywords, value: i+1 });
      }

      var errMsg = fillList.length > 0 ? '' : 'No Results Found!';
      this.setState({ searchResults: resultData, selectOptions: fillList, selectIndex: 1, errorMsg: errMsg, successMsg: '', context: decodeText(resultData[0].context) });
    } 
    catch {
      this.setState({ searchResults: [], selectOptions: [], selectIndex: 0, errorMsg: 'No Results Found!', successMsg: '' });
    }
  }

  async saveChanges() {
    const response = axios.get('/changenote', {
      params: {
        pUser: LoginState.userName,
        pKeywords: this.state.searchResults[this.state.selectIndex-1].keywords,
        pDescription: encodeText(this.state.context),
        pDelete: false
      },
    });
    try {
      var resultData = (await response).data;
      if(resultData.startsWith("Success")) {
        this.setState({ successMsg: 'Note Successfully Updated!', errorMsg: '' });

        // also update the cached data
        var resultItem = this.state.searchResults[this.state.selectIndex-1]
        resultItem.context = encodeText(this.state.context);
      }
      else 
        this.setState({ successMsg: '', errorMsg: resultData });
    } 
    catch {
      this.setState({ searchResults: [], selectOptions: [], selectIndex: 0, errorMsg: 'An Error Occurred While Attempting to Update Note!', successMsg: '' });
    }
  }

  async deleteNote() {
    const response = axios.get('/changenote', {
      params: {
        pUser: LoginState.userName,
        pKeywords: this.state.searchResults[this.state.selectIndex-1].keywords,
        pDescription: '',
        pDelete: true
      },
    });
    try {
      var resultData = (await response).data;
      if(resultData.startsWith("Success")) {
        
        // also remove item
        this.state.searchResults.splice(this.state.selectIndex-1, 1);
        var fillList = [];
        for (var i = 0; i < this.state.searchResults.length; i++) {
          fillList.push({ label: this.state.searchResults[i].keywords, value: i+1 });
        }
        this.setState({ successMsg: 'Note Successfully Deleted!', errorMsg: '', showPopup: false, selectOptions: fillList, selectIndex: 1, 
          context: (this.state.searchResults.length > 0) ? decodeText(this.state.searchResults[0].context) : ''});
      }
      else 
        this.setState({ successMsg: '', errorMsg: resultData, showPopup: false });
    } 
    catch {
      this.setState({ searchResults: [], selectOptions: [], selectIndex: 0, errorMsg: 'An Error Occurred While Attempting to Update Note!', successMsg: '', showPopup: false });
    }
  }
  
  captureInput = e => { 
    if(e.target.name === "selectIndex") {
      this.setState({ 
        [e.target.name]: e.target.value,
        context: decodeText(this.state.searchResults[e.target.value-1].context), 
        errorMsg: '', 
        successMsg: ''
      });
    }
    else 
      this.setState({[e.target.name]: e.target.value, successMsg: '', errorMsg: ''}); 
  }

  searchKeyPressed = e => { if(e.charCode === 13) { this.attemptSearch(); } }

  getContent() {
    return (<div style={{paddingLeft: '10px', paddingRight: '10px'}}>
      <br/>
      Are you sure you want to delete the note? Deletions are permanent, 
      with no way to retrieve any removed data.
    </div>);
  }

  render() {
    return (
      <div style={{paddingLeft: '10px', paddingTop: '10px'}}>
        <Header>Find Note</Header>
        <br/>
        <table><tbody>
          <tr>
            <td width="120px" style={{verticalAlign: 'top'}}>Keyword Search</td>
            <td width="10px"></td>
            <td width="350px"><TextBox width="300px" type='text' name='keywords' value={this.state.keywords} onChange={this.captureInput} onKeyPress={this.searchKeyPressed} /></td>
          </tr>
          <tr height="10px" />
          <tr>
            <td colSpan="2" />
            <td width="350px"><Button onClick={() => this.attemptSearch()}>Search</Button></td>
          </tr>

          {this.state.selectOptions.length > 0 && (<>
            <tr height="50px" />
            <tr>
              <td width="120px" style={{verticalAlign: 'top'}}>({this.state.searchResults.length} Matches Found)</td>
              <td width="10px"></td>
              <td width="350px">
                <Select width="300px" 
                  name="selectIndex"
                  onChange={this.captureInput} 
                  options={this.state.selectOptions} 
                  value={this.state.selectIndex} />
              </td>
            </tr>          
            <tr>
              <td width="120px" style={{verticalAlign: 'top'}}>Context</td>
              <td width="10px"></td>
              <td width="350px"><TextArea width="300px" height="150px" type='text' name='context' value={this.state.context} onChange={this.captureInput} /></td>
            </tr>
            <tr height="10px" />
            <tr>
              <td colSpan="2" />
              <td width="350px">
                <Button onClick={() => this.setState({showPopup: true})}>Delete Note</Button>
                &nbsp;
                {decodeText(this.state.searchResults[this.state.selectIndex-1].context) !== this.state.context && (
                  <Button onClick={() => this.saveChanges()}>Save Changes</Button>
                )}
                </td>
            </tr>          
          </>)}
        </tbody></table>
        <br/>
        <ErrorMessage>{this.state.errorMsg}</ErrorMessage>
        <SuccessMessage>{this.state.successMsg}</SuccessMessage>
        <ConfirmPopup
          title="Delete"
          show={this.state.showPopup}
          onClose={() => this.setState({ showPopup: false })}
          onSubmit={() => this.deleteNote()}
          buttonMessage="Close"
          displayContent={this.getContent()}
        />
      </div>
    );
  }
}
