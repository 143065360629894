import React, { Component } from 'react';
import { Header, TextBox, Button, ErrorMessage } from '../helpers/LayoutComponents';
import { encodeText, decodeText } from '../helpers/utils';
import LoginState from '../helpers/LoginState';
import jwt from 'jsonwebtoken';
import axios from 'axios';

export default class Login extends Component {

  constructor(props) {
    super(props);

    // if we are already logged in, redirect
    if(LoginState.loggedIn) {
      this.props.history.push("/findnote");
    }

    this.state = { 
      login: '',
      password: '',
      errorMsg: '',
    };
  }

  async attemptLogin() {
    const response = axios.get('/loginuser', {
      params: {
        pLogin: this.state.login,
        pPassword: encodeText(this.state.password)
      },
    });
    const resultMessage = ((await response).data).toString();
    if (resultMessage.startsWith('Success')) {      
      LoginState.loggedIn = true;
      LoginState.userName = this.state.login;
      const token = jwt.sign({ LoginState }, decodeText('dXNpb24=TG9naWNs'), { algorithm: 'HS256', expiresIn: 300 });
      localStorage.setItem('user', JSON.stringify(LoginState));
      localStorage.setItem('token', token);
      this.props.history.push("/findnote");
    } 
    else {
      this.setState({errorMsg: resultMessage});
    }
  }

  captureInput = e => { this.setState({[e.target.name]: e.target.value}); }
  loginKeyPressed = e => { if(e.charCode === 13) { this.attemptLogin(); } }

  render () {
    return (
      <div style={{paddingLeft: '10px', paddingTop: '10px'}}>
        <Header>Log In</Header>
        <br/>
        <table><tbody>
          <tr>
            <td width="100px">UserName</td>
            <td width="10px"></td>
            <td width="250px"><TextBox type='text' name='login' value={this.state.login} onChange={this.captureInput} onKeyPress={this.loginKeyPressed} /></td>
          </tr>
          <tr>
            <td width="100px">Password</td>
            <td width="10px"></td>
            <td width="250px"><TextBox type='password' name='password' value={this.state.password} onChange={this.captureInput} onKeyPress={this.loginKeyPressed} /></td>
          </tr>
          <tr height="10px" />
          <tr>
            <td colSpan="2" />
            <td width="250px"><Button onClick={() => this.attemptLogin()}>Login</Button></td>
          </tr>
        </tbody></table>
        <br/>
        <ErrorMessage>{this.state.errorMsg}</ErrorMessage>
      </div>
    );
  }
}
