import React, { Component } from 'react';
import { encodeText, decodeText } from '../helpers/utils';
import { TextBox, Button, Header } from '../helpers/LayoutComponents';
import LoginState from '../helpers/LoginState';

export default class Converter extends Component {

  constructor(props) {
    super(props);

    // if not logged in, redirect
    if(!LoginState.loggedIn) {
      this.props.history.push("/login");
    }

    this.state = { 
      encrypt: '',
      encrypted: '',
      decrypt: '',
      decrypted: '',
    };
  }

  captureInput = e => { this.setState({[e.target.name]: e.target.value}); }

  attemptEncrypt() {
    this.setState({encrypted: encodeText(this.state.encrypt)});
  }

  attemptDecrypt() {
    this.setState({decrypted: decodeText(this.state.decrypt)});
  }

  render() {
    return (
      <div style={{paddingLeft: '10px', paddingTop: '10px'}}>
        <Header>Encrypt/Decrypt</Header>
        <br/>
        <table><tbody>
          <tr>
            <td width="100px" style={{verticalAlign: 'top'}}>Encrypt</td>
            <td width="10px"></td>
            <td width="350px"><TextBox width="350px" type='text' name='encrypt' value={this.state.encrypt} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td width="100px" style={{verticalAlign: 'top'}}>Encrypted</td>
            <td width="10px"></td>
            <td width="350px"><TextBox width="350px" type='text' name='encrypted' value={this.state.encrypted} readOnly= {true} /></td>
          </tr>
          <tr height="10px" />
          <tr>
            <td colSpan="2" />
            <td width="350px"><Button onClick={() => this.attemptEncrypt()}>Encrypt</Button></td>
          </tr>
          <tr height="30px" />
          <tr>
            <td width="100px" style={{verticalAlign: 'top'}}>Decrypt</td>
            <td width="10px"></td>
            <td width="350px"><TextBox width="350px" type='text' name='decrypt' value={this.state.decrypt} onChange={this.captureInput} /></td>
          </tr>
          <tr>
            <td width="100px" style={{verticalAlign: 'top'}}>Decrypted</td>
            <td width="10px"></td>
            <td width="350px"><TextBox width="350px" type='text' name='decrypted' value={this.state.decrypted} readOnly= {true} /></td>
          </tr>
          <tr height="10px" />
          <tr>
            <td colSpan="2" />
            <td width="350px"><Button onClick={() => this.attemptDecrypt()}>Decrypt</Button></td>
          </tr>
        </tbody></table>
      </div>
    );
  }
}
