import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import PublicRoutes from './helpers/PublicRoutes';

import './index.css'

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render () {
    return (
      <BrowserRouter>
        {this.state.user ? (
          <Route component={() => <PublicRoutes user={this.state.user} />} />
        ) : (
          <Route component={PublicRoutes} />
        )}
      </BrowserRouter>
    );
  }
}
