import { CenterFixed, PageTitle, Link } from '../helpers/LayoutComponents';
import React, { Component } from 'react';
import LoginState from '../helpers/LoginState';

export default class HeaderComponent extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      state1: '',
      state2: ''
    };
  }

  logout() {
    LoginState.loggedIn = false;
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    this.props.history.push('/login');
  }

  render() {
    return (
      <CenterFixed>
        <table><tbody><tr>
          <td width="150px" style={{paddingLeft: '10px', textAlign: 'left'}}><PageTitle>Notes</PageTitle></td>
          <td width="1450px" style={{textAlign: 'right'}}>
            {LoginState.loggedIn ? (<>
              <Link style={{paddingRight: '50px'}} onClick={() => this.logout() }>Log Out ({LoginState.userName})</Link>
              <Link style={{paddingRight: '10px'}} onClick={() => this.props.history.push("/converter")}>Converter</Link>
              <Link style={{paddingRight: '10px'}} onClick={() => this.props.history.push("/findnote")}>Find Note</Link>
              <Link style={{paddingRight: '20px'}} onClick={() => this.props.history.push("/createnote")}>Create Note</Link>
            </>) : (<>
              <Link style={{paddingRight: '20px'}} onClick={() => this.props.history.push("/login")}>Log In</Link>
              <Link style={{paddingRight: '50px'}} onClick={() => this.props.history.push("/createUser")}>Create User</Link>
            </>)}
            
          </td>
        </tr></tbody></table>
      </CenterFixed>
    );
  }
}
