import { InlineBody, InlineCard, InlineHeader, InlineWrapper, InlineClose } from './PopupInlineElements';
import { LeftTD, RightTD, HeaderTable, Button } from './LayoutComponents';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import React, { Component } from 'react';

export default class ConfirmPopup extends Component {  
  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  render() {
    return this.props.show ? (
      <InlineWrapper>
        <InlineCard>
          <InlineHeader>
            <HeaderTable><tbody>
              <tr>
                <LeftTD>{this.props.title}</LeftTD>
                <RightTD width="30px">
                  <InlineClose>
                    <CancelPresentationIcon size="2rem" onClick={this.props.onClose} />
                  </InlineClose>
                </RightTD>
              </tr>
            </tbody></HeaderTable>
          </InlineHeader>
          <InlineBody>
            {this.props.displayContent}
          </InlineBody>
          <table><tbody>
            <tr>
              <td>
                <table style={{margin:'10px', width:'400px'}}><tbody><tr>
                  <td><Button onClick={this.props.onClose}>Cancel</Button></td>  
                  <td width="5px" />
                  <td><Button onClick={this.props.onSubmit}>{this.props.title}</Button></td>  
                  <td width="150px" />
                </tr></tbody></table>
              </td>
            </tr>
          </tbody></table>
        </InlineCard>
      </InlineWrapper>
    ) : null;
  }
}