import styled from 'styled-components';

export const PopupWrapper = styled.div`
  background-color: aliceblue;
  position: fixed;
  top: 60px;
  right: 20px;
  width: 375px;
  border: 2px solid black;
  display: flex;
  align-items: center;    
  border-radius: 2px;
  padding: 10px;
  margin: 10px;
  box-shadow: 2px 2px 2px #4d598a;
`;

export const PopupContent = styled.div`
  padding: 0px 10px 0px 10px;
  flex-grow: 1;
  margin: 0;
  font-family: 'Verdana';
  font-size: 12px;
  white-space: pre-wrap;
  display: block;
`;

export const PopupClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #37426e;

  &:hover { 
    color: #3217ff;
  }
  &:active {
    color: #0c0163;
  }
`;

export const InlineClose = styled.div`
  cursor: pointer;
  color: yellow;
  width: 30px;
  &:hover { 
    color: #fafa9d;
  }
  &:active {
    color: white;
  }
`;

export const FullScreenToggle = styled.div`
  cursor: pointer;
  color: black;
  width: 30px;
  &:hover { 
    color: blue;
  }
  &:active {
    color: green;
  }
`;

export const InlineWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;
    
export const InlineCard = styled.div`
  z-index: 9999;
  width: 300px;
  min-height: 100px;
  max-height: 90vh;
  background-color: white;
  border: 2px solid black;
  margin: 0 auto;
  align-self: center;
  display: flex;
  flex-direction: column;
`;

export const InlineHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #37426e;
  color: white;
  border-bottom: 2px solid black;
`;
    
export const InlineBody = styled.div`
  margin: 0;
  height: 90px;
  background-color: white;
`;